<template>
    <div v-if="!access">
        <NotFound />
    </div>
    <div v-else>
        <div class="pb-2 mb-3 border-bottom">
            <h2>My Leaves</h2>
        </div>
        <nav aria-label="breadcrumb">
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <router-link to="/">Dashboard</router-link>
                </li>
                <li class="breadcrumb-item active" aria-current="page">My Leaves</li>
            </ol>
        </nav>
        <b-container fluid>
            <div v-if="api_error">
                <b-alert
                    :show="alertDismissCountDown"
                    dismissible
                    variant="danger"
                    @dismissed="alertDismissCountDown=0"
                    @dismiss-count-down="alertCountDownChanged"
                >{{api_error}}</b-alert>
            </div>
            <b-row>
               <b-col lg="2" sm="12" class="mb-1rem">
                    <div class="form-group row">
                        <label
                            class="col-lg-3 col-md-2 col-sm-12 col-form-label text-nowrap"
                        >Year</label>
                        <div class="col-lg-9 col-md-10 col-sm-12">
                            <b-form-select
                                v-model="urlBody.year"
                                :options="years"
                                disabled-field="notEnabled"
                            ></b-form-select>
                        </div>
                    </div>
                </b-col>
                <b-col lg="1">
                        <button
                            type="button"
                            class="btn btn-primary text-right "
                            v-on:click="getLeaves(urlBody)"
                        >Filter</button>
                </b-col>
                <b-col lg="3" class="mb-1rem">
                    <router-link
                        v-if="hasPermission('CAN_APPLY_LEAVE')"
                        :to="{name: 'apply-leave'}"
                        class="btn btn-primary text-right"
                        tag="button"
                    >
                        <font-awesome-icon icon="plus"></font-awesome-icon>&nbsp; Apply Leave
                    </router-link>
                </b-col>
                
                <b-col lg="1" class="mb-1rem text-right">
                </b-col>
                <b-col lg="5" class="mb-1rem text-right">
                    <router-link
                        v-if="my_compoffs"
                        :to="{name: 'comp-offs'}"
                        class="btn btn-light text-right mx-2"
                        tag="button"
                    >
                        Comp Offs
                    </router-link>
                    <router-link
                        v-if="leaveEncash"
                        :to="{name: 'leave-encash'}"
                        class="btn btn-light text-right mx-2"
                        tag="button"
                    >
                        Leave Encash
                    </router-link>
                    <router-link
                        v-if="hasPermission('CAN_VIEW_MY_LEAVE_BALANCE')"
                        :to="{name: 'my-leave-balance'}"
                        class="btn btn-light text-right mx-2"
                        tag="button"                        
                    >
                       Leave Balance History
                    </router-link>
                </b-col>
            </b-row>

            <b-table
                show-empty
                striped
                hover
                :items="items"
                :fields="fields"
                no-local-sorting
                @sort-changed="customSorting"
                responsive
            >
                <template v-slot:cell(actions)="row">
                    <span class="actions">
                        <span v-if="row.item.status == 'APPLIED' && hasPermission('CAN_APPLY_LEAVE')" class="text-danger circle" v-b-tooltip.hover title="Cancel"  @click="info(row.item, row.index, $event.target)">
                            <font-awesome-icon
                                style="cursor:pointer;font-size:17px"
                                icon="times"
                            ></font-awesome-icon>
                        </span>
                    </span> 
                </template>
                <template v-slot:cell(status)="row">
                    <span v-if="row.item.status == 'APPLIED'"><b-badge variant="info">{{row.item.status}}</b-badge></span>
                    <span v-else-if="row.item.status == 'APPROVED'"><b-badge variant="success">{{row.item.status}}</b-badge></span>
                    <span v-else-if="row.item.status == 'REJECTED'"><b-badge variant="danger">{{row.item.status}}</b-badge></span>
                    <span v-else><b-badge variant="dark">{{row.item.status}}</b-badge></span>
                </template>
                <template v-slot:cell(created_at)="row">
                    {{ convertToDate(row.item.created_at) }}
                </template>
                <template v-slot:cell(reason)="row">
                    <p class="text-truncate" style="width:70px" v-b-tooltip.hover :title="row.item.reason">
                        {{row.item.reason}}
                    </p>
                </template>
            </b-table>
        </b-container>

        <!-- Info modal -->
        <b-modal
            :id="infoModal.id"
            :title="infoModal.title"
            centered
            size="md"
            @hide="resetInfoModal"
        >
            <p v-html="message" class="float-left"></p>
            <div slot="modal-footer" class="w-100">
                <b-button class="mt-3 float-right" @click="cancel()">No</b-button>
                <b-button
                    class="mt-3 float-right"
                    variant="danger"
                    v-on:click="cancelLeave(infoModal.item_id)"
                    style="margin-right:10px;"
                >Yes</b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import NotFound from "../../errors/NotFound";
import default_error from "../../../assets/js/global";
export default {
    components: {
        NotFound
    },
    data() {
        return {
            urlBody: {
                query: "",
                sort_by: "created_at",
                order: "desc",
                year: new Date().getFullYear(),
            },
            api_error: "",
            sortBy: "id",
            sortDesc: false,
            fields: [
                { key: "from_date", label: "From Date", sortable: true,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { key: "to_date", label: "To Date", sortable: true,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { key: "days", label: "Days", sortable: true},
                { key: "created_at" ,label: "Applied Date",sortable: true,
                    formatter: value => {
                        return this.dateformat(value);
                    }
                },
                { label: 'Leave Type', key: "type", sortable: true},
                { key: "reason" ,tdClass: 'reasonClass'},
                { key: "status",sortable: true},
                { key: "actions", label: "Actions" }
            ],
            items: null,
            totalRows: 1,
            access: true,
            alertDismissCountDown: 0,
            alertDismissSecs: 10,
            infoModal: {
                id: "modal-sm",
                title: "",
                item_id: ""
            },
            message:"",
            remaining_leaves: '',
            my_compoffs :false,
            leaveEncash :false,
            years: [],
            total_years:2,
        };
    },
    mounted() {
        this.access = this.hasPermission("CAN_APPLY_LEAVE");
        this.getEmployee();
        this.getLeaves(this.getDefaultFilters());
        this.getRemainingLeaves();
        this.getYears();
    },
    methods: {
        getYears() {
            const currentYear = (new Date()).getFullYear();
            for (let index = 0; index < this.total_years; index++) {
                this.years.push({
                    value: currentYear-index, text:currentYear-index
                })
            }
        },

        alertCountDownChanged(alertDismissCountDown) {
            this.alertDismissCountDown = alertDismissCountDown;
        },

        showAlert() {
            this.alertDismissCountDown = this.alertDismissSecs;
        },

        info(item, index, button) {
            this.infoModal.title = `Cancel Confirmation`;
            this.message = 'Are you sure you want to Cancel this Leave?';
            this.infoModal.item_id = item.id;
            this.$root.$emit("bv::show::modal", this.infoModal.id, button);
        },
        resetInfoModal() {
            this.infoModal.title = "";
            this.infoModal.item_id = "";
        },

        cancel: function() {
            this.$root.$emit("bv::hide::modal", this.infoModal.id);
        },

        cancelLeave(id) {
            this.startProgressBar();
            this.axios.post(this.$api.cancel_leave, {id:id}, this.getAuthHeaders()).then(() => {
                this.$root.$emit("bv::hide::modal", this.infoModal.id);
                this.api_error = "";
                this.getLeaves();
                this.getRemainingLeaves();
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        convertToDate(D){
            var date = new Date(D);
            return ('0' + date.getDate()).slice(-2) + '-' + ('0' + (date.getMonth()+1)).slice(-2) + '-'+ date.getFullYear();
        },

        getDefaultFilters() {
            let api_params = {
                sort_by: this.urlBody.sort_by,
                order: this.urlBody.order,
                year: this.urlBody.year
            };
            return api_params;
        },
        
        getLeaves: function(params) {
            this.startProgressBar();
            var query = this.getAuthHeaders();
            query["params"] = this.deleteEmptyKeys(params);
            this.axios.get(this.$api.get_leaves, query).then(response => {
                const result = response.data.data.leaves;
                this.items = result.data;
                this.api_error = "";
                this.updateProgressBar(true);
                this.$router.push({
                    path: this.$route.params[0],
                    query: this.deleteEmptyKeys(params)
                }).catch(()=>{})
            }).catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.items = [];
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        customSorting: function(ctx) {
            if (ctx.sortBy == "" || ctx.sortBy == null) {
                return;
            }
            let urlBody = this.deleteEmptyKeys(this.$route.query);
            urlBody.order = ctx.sortDesc ? "asc" : "desc";
            urlBody.sort_by = ctx.sortBy;
            urlBody.page = 1;
            this.api_error = "";
            this.getLeaves(urlBody);
        },

        getRemainingLeaves: function() {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            query['params'] = {};
            this.axios.get(this.$api.get_remaining_leaves, query).then(response => {
                this.remaining_leaves = response.data.data;
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },

        getEmployee: function() {
            this.startProgressBar();
            let query = this.getAuthHeaders();
            this.axios.get(this.$api.get_employees, query).then(response => {
                const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                const leaves_encash_months = this.$store.getters.getAppSetting("payroll", "leave_encash_month");
                const current_date = new Date();
                const current_month = months[current_date.getMonth()];
                let my_compoffs = response.data.data.leave_group.compoff;
                if(response.data.data.leave_group.leaves_encashed && leaves_encash_months.includes(current_month)) {
                    this.leaveEncash = true;
                }
                if (my_compoffs && my_compoffs.comp_off_holidays && my_compoffs.comp_off_week_offs && ((my_compoffs.comp_off_holidays.length !== 0) || (my_compoffs.comp_off_week_offs.length !== 0))){
                   this.my_compoffs = true; 
                }
                this.updateProgressBar(true);
            })
            .catch(err => {
                if (!err.response) {
                    this.api_error = default_error.server_error;
                } else if (
                    err.response &&
                    err.response.data &&
                    err.response.data.message
                ) {
                    this.api_error = err.response.data.message;
                } else {
                    this.api_error = default_error.server_error;
                    console.error(err.response.data);
                }
                this.updateProgressBar(false);
                this.showAlert();
            });
        },
        dateformat (date) {
            var dateFormat = new Date(date);
            var dd = String(dateFormat.getDate()).padStart(2, '0');
            var mm = String(dateFormat.getMonth() + 1).padStart(2, '0');
            var yyyy = dateFormat.getFullYear();
            return dd + '-' + mm + '-' + yyyy;
        },
    }
};
</script>
<style lang="scss">
@import "../../../assets/css/custom.scss";
</style>

<style >
.reasonClass {
    width:15% !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 1px;

}
.btn-outline-light-black {
    border: 1px solid black;
}
</style>